import {ENABLE_GRID_OVERLAY} from 'config/index';
import {useRouter} from 'next/router';
// import Script from 'next/script';
import React, {ReactNode, useEffect, useState} from 'react';

import {generateWrapperConfig} from 'utils/strapi-components/getComponent';

import {Box} from 'components/basic-components';
import SkipLinks from 'components/layout-components/SkipLinks';
import ContactOptionsModal from 'components/section-components/ContactOptionsModal';
import BPR from 'components/section-components/bpr/BestPriceRechner';

// import BackToTopButton from '../BackToTopButton';
import {DiagonalWrapper} from '../DiagonalWrapper/DiagonalWrapper';
import Footer from '../Footer';
import FooterSmall from '../FooterSmall';
import GridOverlay from '../GridOverlay/GridOverlay';
import Navbar from '../Navbar';
import styles from './Layout.module.scss';

const wrapperDefaultConfig = generateWrapperConfig();
export interface ITranslatedSlugs {
  locale: string;
  slug: string;
}
export const Layout = ({
  children,
  translatedSlugs,
  published = true,
  hideBPR = false,
}: {
  children: ReactNode;
  translatedSlugs: ITranslatedSlugs[];
  hideBPR?: boolean;
  published?: boolean;
}) => {
  const router = useRouter();
  const {
    query: {reservationId},
  } = router;

  const [isFooterVisible, setIsFooterVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const footerElement = document.getElementById('footer');
      if (footerElement) {
        const rect = footerElement.getBoundingClientRect();
        const isVisible = rect.top <= window.innerHeight;
        setIsFooterVisible(isVisible);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <ContactOptionsModal isFooterVisible={isFooterVisible}>
      <div>
        {ENABLE_GRID_OVERLAY ? <GridOverlay /> : null}
        <SkipLinks />
        <Navbar translatedSlugs={translatedSlugs} />
        {hideBPR ? null : <BPR withSessionConfig key={(reservationId as string) || 'bpr'} />}
        <span className={styles.anchorLink}>
          <span id="mainAnchor" tabIndex={-1} />
        </span>
        {children}
        <DiagonalWrapper
          noPadding
          config={{...wrapperDefaultConfig, diagonal: {bottom: true, top: true}}}
          background={'yellow'}
        >
          <Footer />
        </DiagonalWrapper>
        {!published ? (
          <Box sx={{bottom: 150, zIndex: 10000, position: 'fixed'}}>
            <div
              style={{
                background: 'white',
                display: 'flex',
                padding: 5,
                width: 'auto',
                color: 'red',
                border: '1px solid black',
              }}
            >
              Diese Page ist ein Draft
            </div>
          </Box>
        ) : null}
        <DiagonalWrapper
          config={{...wrapperDefaultConfig, diagonal: {bottom: false, top: true}}}
          background={'black'}
          noPadding
        >
          <FooterSmall />
        </DiagonalWrapper>
      </div>
    </ContactOptionsModal>
  );
};
export default Layout;
