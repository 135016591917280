import { Grid } from "components/basic-components";
import { TVariantsType } from "../ContactOptionsModal";
import styles from "../ContactOptionsModal.module.scss";
import MarkdownContent from "components/basic-components/MarkdownContent";
import { ContactOption } from "./ContactOption";
export const ContactOptions = ({
    customText,
    variants,
    selectedVariant,
    setSelectedVariant,
  }: {
    variants: TVariantsType[];
    selectedVariant: TVariantsType;
    setSelectedVariant: (variant: TVariantsType) => void;
    customText: string;
  }) => {
    return (
      <Grid
        container
        spacing={4}
        direction="column"
        justifyContent="space-between"
        alignItems={{xxs: 'center', md: 'start'}}
        className={styles.optionsWrapper}
      >
        {variants.map(variant => (
          <Grid key={variant} xxs={12}>
            <ContactOption
              active={selectedVariant === variant}
              onClick={() => setSelectedVariant(variant)}
              key={variant}
              variant={variant}
            />
          </Grid>
        ))}
        <Grid sx={customText ? {marginTop: '20px'} : {}} xxs={12}>
          <MarkdownContent className={styles.infoText} content={customText} />
        </Grid>
      </Grid>
    );
  };