import {NavEntry} from 'context/navPropsContext';
import {useTranslation} from 'hooks/translations/useCustomTranslation';
import React, {useCallback, useState} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import {Theme} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import {Box, Typography} from 'components/basic-components';
import {CustomIcon} from 'components/basic-components/CustomIcon/CustomIcon';

import {DropDownLink} from './DropDownLink';
import styles from './Menu.module.scss';

const DropDownMenu = ({
  headerLinks,
  headerMenuLinks,
}: {
  headerLinks: NavEntry[];
  headerMenuLinks: NavEntry[];
}) => {
  const {t} = useTranslation('common');
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const closeMenu = useCallback(() => setDropdownOpen(false), [setDropdownOpen]);

  const toggleDropdown = useCallback(() => {
    setDropdownOpen(prevDropdownOpen => !prevDropdownOpen);
  }, [setDropdownOpen]);
  return (
    <OutsideClickHandler onOutsideClick={closeMenu}>
      <Box style={{position: 'relative', display: 'flex'}}>
        <button aria-label="Menü" className={styles.dropDownMenuButton} onClick={toggleDropdown}>
          <CustomIcon name="menu" className={styles.icon} />
          <Typography className={styles.dropDownMenuButtonText} variant="menuLabel">
            {t('menu')}
          </Typography>
        </button>
        {isDropdownOpen && (
          <div className={styles.dropDownMenuWrapper}>
            <ul className={styles.ul}>
              {isMobile
                ? headerMenuLinks?.map((link: NavEntry, index) => {
                    return (
                      <DropDownLink
                        index={index}
                        key={link.id}
                        link={link}
                        type="header"
                        menustyle="mobile"
                        closeMenu={closeMenu}
                      />
                    );
                  })
                : null}
              {headerLinks?.map((link: NavEntry, index) => {
                return (
                  <DropDownLink
                    index={index}
                    key={link.id}
                    link={link}
                    type="burger"
                    menustyle={isMobile ? 'mobile' : 'desktop'}
                    closeMenu={closeMenu}
                  />
                );
              })}
            </ul>
          </div>
        )}
      </Box>
    </OutsideClickHandler>
  );
};

export default DropDownMenu;
