import {useTranslation} from 'hooks/translations/useCustomTranslation';

import {Box, Typography} from 'components/basic-components';
import {CustomIcon} from 'components/basic-components/CustomIcon/CustomIcon';

import {TVariantsType, variantsConfig} from '../ContactOptionsModal';
import styles from '../ContactOptionsModal.module.scss';

export const MobileHeader = ({
  variant,
  handleBackButton,
}: {
  variant: TVariantsType;
  handleBackButton: () => void;
}) => {
  const {t} = useTranslation('ContactOptionsModal');
  const variantConfig = variantsConfig.find(({type}) => type === variant);
  if (!variantConfig) {
    return null;
  }
  const {type, Icon} = variantConfig;
  return (
    <Box className={styles.mobileHeaderWrapper}>
      <button onClick={handleBackButton} className={styles.backButton} aria-label={t('backButton')}>
        <CustomIcon
          height={'100%'}
          // width={'24px'}
          name="chevronLeft"
        />
      </button>
      <Typography className={styles.mobileHeaderTitle}>
        <Icon />
        <b>{t(type)}</b>
      </Typography>
    </Box>
  );
};
