import {NavEntry} from 'context/navPropsContext';
import {useCallback, useContext} from 'react';

import {getHeaderMenuDataLayer} from 'utils/tagManagerHelpers/gtmEventsParsing';

import {NextLink, Typography} from 'components/basic-components';
import {ConsentContext} from 'components/section-components/ConsentManager';

import styles from './Navbar.module.scss';

export const NavbarLink = ({
  link,
  index,
  onClick = () => {},
}: {
  link: NavEntry;
  onClick?: () => void;
  index: number;
}) => {
  const {pushDataLayer} = useContext(ConsentContext);
  const clickHandler = useCallback(() => {
    pushDataLayer(getHeaderMenuDataLayer({...link, index, type: 'header', menustyle: 'desktop'}));
    onClick();
  }, [link, index, pushDataLayer, onClick]);
  return (
    link.href && (
      <Typography
        onClick={clickHandler}
        key={link.id}
        className={styles.linkText}
        variant="menuLabel"
      >
        <NextLink id={`navbarLink${index}`} href={`${link.href}`}>
          {link.title}
        </NextLink>
      </Typography>
    )
  );
};
