import clsx from 'clsx';
import {useTranslation} from 'hooks/translations/useCustomTranslation';
import Image from 'next/image';
import Favicon from 'public/favicon-48x48.png';

import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined';

import {Box, Typography} from 'components/basic-components';
import {CustomIcon} from 'components/basic-components/CustomIcon/CustomIcon';

import styles from '../ContactOptionsModal.module.scss';

export const MobileOpenButton = ({setMobileMenuOpen, mobileMenuOpen, isHidden = false}) => {
  const {t} = useTranslation('ContactOptionsModal');

  return (
    <Box
      className={clsx(styles.mobileOpenButtonWrapper, {
        [styles.displayNone]: isHidden,
        [styles.mobileMenuOpen]: mobileMenuOpen,
      })}
    >
      <div className={clsx(styles.headsetIconWrapper, {[styles.displayNone]: mobileMenuOpen})}>
        <Image
          alt=""
          className={styles.notificationIcon}
          src={Favicon}
          height={Favicon?.height}
          width={Favicon?.width}
        />
      </div>
      <Typography className={clsx(styles.closeText, {[styles.displayNone]: !mobileMenuOpen})}>
        {t('closeModal')}
      </Typography>
      <button
        tabIndex={-1}
        role="button"
        aria-label={mobileMenuOpen ? t('closeModal') : t('openModalButtonLabel')}
        className={styles.mobileOpenButton}
        onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
      >
        {mobileMenuOpen ? (
          <CustomIcon name="close" />
        ) : (
          <HeadsetMicOutlinedIcon sx={{height: '80%', width: '80%'}} />
        )}
      </button>
    </Box>
  );
};
