import {NavPropsContext} from 'context/navPropsContext';
import {useTranslation} from 'hooks/translations/useCustomTranslation';
import Image from 'next/image';
import Logo from 'public/assets/logo_starcar2x.png';
import React, {FC, useContext} from 'react';

import {Theme, useMediaQuery} from '@mui/material';

import {palette} from 'styles/mui/scss';

import {Box} from 'components/basic-components';
import {NextLink} from 'components/basic-components/NextLink/Link';

import ContentWrapper from '../ContentWrapper/ContentWrapper';
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch';
import DropDownLogin from '../Menu/DropDownLogin';
import DropDownMenu from '../Menu/DropDownMenu';
import styles from './Navbar.module.scss';
import {NavbarLink} from './NavbarLink';
import {NavbarProps} from './types';

const Navbar: FC<NavbarProps> = ({translatedSlugs}: NavbarProps) => {
  const {t} = useTranslation('common');
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const {headerLinks, headerMenuLinks} = useContext(NavPropsContext);

  return (
    <>
      <span className={styles.anchorLink}>
        <span id="navigationAnchor" tabIndex={-1} />
      </span>
      <ContentWrapper
        id={'headerWrapper'}
        sx={{backgroundColor: palette.colorDarkGrey, position: 'sticky', top: 0, zIndex: 1302}}
      >
        <Box className={styles.contentWrapper}>
          <Box sx={{marginRight: {sm: '40px', xl: '80px'}}}>
            <NextLink style={{display: 'flex'}} href="/">
              <Image src={Logo} alt={t('logo')} height={25} />
            </NextLink>
          </Box>
          <Box className={styles.linkWrapper}>
            {isDesktop && (
              <Box id={'headerMenuLinks'}>
                {headerMenuLinks?.map((link, index) => (
                  <NavbarLink index={index} key={link.id} link={link} />
                ))}
              </Box>
            )}
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              <LanguageSwitch translatedSlugs={translatedSlugs} />
              <DropDownLogin />
              <DropDownMenu headerLinks={headerLinks} headerMenuLinks={headerMenuLinks} />
            </Box>
          </Box>
        </Box>
      </ContentWrapper>
    </>
  );
};

export default Navbar;
