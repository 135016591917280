import { ContactOptionsContactOptionWhatsappComponentQrcode } from "lib/api/strapi";
import Image from "next/image";
export const StrapiImage = ({
    image,
    className = "",
  }: {
    image: ContactOptionsContactOptionWhatsappComponentQrcode;
    className?: string;
  }) => {
    const {url, height, width, alternativeText} = image?.data?.attributes;
    return (
      <Image
        className={className}
        src={url}
        height={height}
        width={width}
        alt={alternativeText}
      />
    );
  };
  