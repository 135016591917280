import { forwardRef } from 'react';
import { useTranslation } from 'hooks/translations/useCustomTranslation';
import { Box, Typography } from 'components/basic-components';
import styles from '../ContactOptionsModal.module.scss';

export const ContactHeader = forwardRef<HTMLDivElement, {}>((props, ref) => {
  const { t } = useTranslation('ContactOptionsModal');
  
  return (
    <Box className={styles.headerWrapper} ref={ref}>
      <Typography className={styles.title} component="h2">
        {t('title')}
      </Typography>
      <Typography component="span" className={styles.subTitle}>{t('subtitle')}</Typography>
    </Box>
  );
});

ContactHeader.displayName = 'ContactHeader';