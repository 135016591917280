// ContactOptionsModalContext.tsx
import { TVariantsType } from 'components/section-components/ContactOptionsModal/ContactOptionsModal';
import React, { createContext, useContext } from 'react';

// Define the shape of the context value
interface ContactOptionsModalContextType {
  openModal: () => void;
  closeModal: () => void;
  openModalAndSelectVariant: (variant: TVariantsType) => void;
  openFeedback: () => void;
}
interface ContactOptionsModalContextProps extends ContactOptionsModalContextType {
  children: React.ReactNode;
}
// Create the Context with TypeScript type
const ContactOptionsModalContext = createContext<ContactOptionsModalContextType | undefined>(undefined);

// Define a Provider Component
export const ContactOptionsModalProvider: React.FC<ContactOptionsModalContextProps> = ({ children , openModal,  closeModal,  openModalAndSelectVariant,openFeedback}) => {
  // Function to open modal

  // The value that will be provided to the context
  const value: ContactOptionsModalContextType = {
    openModal,
    closeModal,
    openFeedback,
    openModalAndSelectVariant,
  };

  return (<ContactOptionsModalContext.Provider value={value}>
      {children}
    </ContactOptionsModalContext.Provider>
  );
};

// Custom hook to use the ContactOptionsContext
export const useContactOptions = (): ContactOptionsModalContextType => {
    const context = useContext(ContactOptionsModalContext);
    if (context === undefined) {
      throw new Error('useContactOptions must be used within a ContactOptionsProvider');
    }
    return context;
  };