import { useTranslation } from "hooks/translations/useCustomTranslation";
import { TVariantsType, variantsConfig } from "../ContactOptionsModal";
import { Box, Button, Typography } from "components/basic-components";
import styles from "../ContactOptionsModal.module.scss";
export const ContactOption = ({
    variant,
    onClick,
    active,
  }: {
    variant: TVariantsType;
    onClick: () => void;
    active: boolean;
  }) => {
    const {t} = useTranslation('ContactOptionsModal');
    const variantConfig = variantsConfig.find(({type}) => type === variant);
    if (!variantConfig) {
      return null;
    }
    const {type, Icon} = variantConfig;
  
    return (
      <Button
        variant={active ? 'inverted' : 'secondary'}
        onClick={onClick}
        aria-label={t(variant)}
        type="button"
        sx={{width: '100%', justifyContent: 'start', borderRadius: '4px'}}
      >
        <Box className={styles.buttonContent}>
          <Icon />
          <Typography>{t(variant)}</Typography>
        </Box>
      </Button>
    );
  };