import {useTranslation} from 'hooks/translations/useCustomTranslation';
import React, {useCallback, useState} from 'react';

import {Box, Drawer, Typography} from '@mui/material';

import {palette} from 'styles/mui/scss';

import {CustomIcon} from 'components/basic-components/CustomIcon/CustomIcon';

import FeedbackForm from './FeedbackForm';
import FeedbackThankYou from './FeedbackThankYou';

const DrawerCloseHeader = ({onClose}) => {
  const {t} = useTranslation('feedback');
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        position: 'sticky',
        top: 0,
        backgroundColor: palette.colorDarkGrey, // Adjust to match the dark background in the image
        color: '#fff', // White text
      }}
    >
      <button aria-label={t('close')} style={{padding: 0, border: "none", background: 'inherit'}}><CustomIcon
        onClick={onClose}
        name="chevronLeft"
        height="30px"
        style={{margin: '10px 16px', cursor: 'pointer'}}
      /></button>
      <div
        style={{
          height: 'calc(100% - 10px)',
          width: '1px',
          backgroundColor: 'grey',
        }}
      ></div>
      <button aria-label={t('close')} style={{padding: 0, border: "none", background: 'inherit'}}><Typography onClick={onClose} sx={{ml: 4, cursor: 'pointer', fontWeight: 'bold'}}>
        {t('close')}
      </Typography></button>
    </Box>
  );
};
export type TFeedBackOptions = 'good' | 'mid' | 'bad';
const FeedbackDrawer = ({open, onClose}) => {
  const [feedback, setFeedback] = useState('');
  const [selectedFeedback, setSelectedFeedback] = useState<TFeedBackOptions>(null);
  const [isSent, setIsSent] = useState(false);
  const onCloseHandler = useCallback(() => {
    setFeedback('');
    setSelectedFeedback(null);
    setIsSent(false);
    onClose();
  }, []);
  return (
    <Drawer sx={{zIndex: 9998}} anchor="right" open={open} onClose={onCloseHandler}>
      <DrawerCloseHeader onClose={onCloseHandler} />
      {!isSent ? (
        <FeedbackForm
          feedback={feedback}
          setFeedback={setFeedback}
          selectedFeedback={selectedFeedback}
          setSelectedFeedback={setSelectedFeedback}
          setIsSent={setIsSent}
        />
      ) : (
        <FeedbackThankYou selectedFeedback={selectedFeedback} />
      )}
    </Drawer>
  );
};

export default FeedbackDrawer;
