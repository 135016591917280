import { Button, Grid, NextLink } from "components/basic-components"
import { TVariantsType } from "./ContactOptionsModal"
import ContactForm from "components/form-components/ContactForm"
import { useContext } from "react";
import { ContactOptionsModalPropsContext } from "context/ContactOptionsModalPropsContext";
import MarkdownContent from "components/basic-components/MarkdownContent";
import { ContactOptionsContactOptionWhatsappComponent, ContactOptionsContactOptionCallbackComponent, ContactOptionsContactOptionPhoneComponent, ContactOptionsContactOptionMailComponent } from "lib/api/strapi";
import { useTranslation } from "next-i18next";
import { StrapiImage } from "./components/StrapiImage";
import styles from './ContactOptionsModal.module.scss';
import { useContactOptions } from "context/ContactOptionsModalContext";
export const ContactOptionsContentInner = ({variant}: {variant: TVariantsType}) => {
    const contactOptionsModals = useContext(ContactOptionsModalPropsContext);

    const {contactOptionCallback, contactOptionMail,contactOptionPhone, contactOptionWhatsapp} = contactOptionsModals || {}
    switch(variant) {
        case "whatsapp":
            return <ContactOptionsWhatsappContent {...contactOptionWhatsapp} />
        case "callback":
            return <ContactOptionsCallbackContent {...contactOptionCallback} />
        case "phone":
            return <ContactOptionsPhoneContent {...contactOptionPhone}/>
        case "mail":
            return <ContactOptionsEmailContent {...contactOptionMail}/>
        default:
            return null
    }
}
export const ContactOptionsContent = ({variant}: {variant: TVariantsType}) => {
    return (
        <Grid>
            <Grid container>
                <ContactOptionsContentInner variant={variant} />
            </Grid>
        </Grid>)
}
const ContactOptionsWhatsappContent = (props: ContactOptionsContactOptionWhatsappComponent) => {
    const {t} = useTranslation("ContactOptionsModal");
    // @ts-ignore
    return <Grid xxs={12} lg={9} xxl={6} container direction={'column'}><MarkdownContent content={props?.content}/><StrapiImage className={styles.qrImage} image={props?.qrcode}></StrapiImage><Button sx={{marginTop: "20px", width: 'max-content'}} component={NextLink} href={props?.link} target="_blank">{t('whatsappLink')}</Button></Grid>

}
const ContactOptionsCallbackContent = (props: ContactOptionsContactOptionCallbackComponent) => {
    const {t} = useTranslation("ContactOptionsModal");
    // @ts-ignore
    return <Grid xxs={12} lg={9} xxl={6}><MarkdownContent content={props?.content}/><Button sx={{marginTop: "20px"}} component={NextLink} href={props?.callbackLink} target="_blank">{t('scheduleCallback')}</Button></Grid>

}

const ContactOptionsPhoneContent = (props: ContactOptionsContactOptionPhoneComponent) => {
    return <Grid xxs={12} lg={9} xxl={6}><MarkdownContent content={props?.content}/></Grid>

}
const ContactOptionsEmailContent = (_props: ContactOptionsContactOptionMailComponent) => {
    const {closeModal} = useContactOptions();
    return <ContactForm isInModal onSuccess={closeModal} />
}