import clsx from 'clsx';
import {useTranslation} from 'hooks/translations/useCustomTranslation';

import ChatBubbleIcon from '@mui/icons-material/ChatBubble';

import {Box, Typography} from 'components/basic-components';

import styles from '../ContactOptionsModal.module.scss';
import ContactBubbleNotification from './ContactBubbleNotification';

export const DesktopOpenButton = ({
  setOpen,
  buttonText,
  open,
  notificationText,
  color = 'yellow',
  position = 'top',
  timeout = 5000,
  showDuration = 5000,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  color?: 'yellow' | 'black';
  position?: 'top' | 'bottom';
  buttonText: string;
  notificationText: string;
  timeout?: number;
  showDuration?: number;
}) => {
  const {t} = useTranslation('ContactOptionsModal');
  return (
    <div className={styles.variableWrapper}>
      <ContactBubbleNotification
        timeout={timeout}
        showDuration={showDuration}
        position={position}
        notificationText={notificationText}
        onClick={() => setOpen(true)}
      />
      <Box
        className={clsx(styles.openButtonWrapper, styles[position], {
          [styles.scrollbarAdjustOnOpen]: open,
        })}
      >
        <button
          tabIndex={-1}
          aria-label={t('openModalButtonLabel')}
          className={clsx(styles.openButton, styles[color])}
          onClick={() => setOpen(true)}
        >
          <ChatBubbleIcon />
          <Typography className={styles.openButtonText}>{buttonText}</Typography>
        </button>
      </Box>
    </div>
  );
};
