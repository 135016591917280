import {useTranslation} from 'hooks/translations/useCustomTranslation';

import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import TextsmsIcon from '@mui/icons-material/Textsms';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import {Box, Button, Typography} from 'components/basic-components';

import styles from '../ContactOptionsModal.module.scss';

export const MobileMenu = ({goToWhatsapp, goToContact, goToFeedback}) => {
  const {t} = useTranslation('ContactOptionsModal');

  return (
    <Box className={styles.mobileMenuInner}>
      <Button
        onClick={goToWhatsapp}
        variant="secondary"
        sx={{width: '100%', display: 'inline-flex', borderRadius: '4px'}}
      >
        <Box className={styles.buttonContent}>
          <WhatsAppIcon />
          <Typography>{t('whatsapp')}</Typography>
        </Box>
      </Button>
      <Button onClick={goToContact} variant="secondary" sx={{width: '100%', display: 'inline-flex'}}>
        <Box className={styles.buttonContent}>
          <ChatBubbleIcon />
          <Typography>{t('contact')}</Typography>
        </Box>
      </Button>
      <Button
        onClick={goToFeedback}
        variant="contained"
        sx={{width: '100%', display: 'inline-flex'}}
      >
        <Box className={styles.buttonContent}>
          <TextsmsIcon />
          <Typography>{t('feedback')}</Typography>
        </Box>
      </Button>
    </Box>
  );
};
