import { CustomIcon } from "components/basic-components/CustomIcon/CustomIcon";
import { useTranslation } from "hooks/translations/useCustomTranslation";
import styles from "../ContactOptionsModal.module.scss";
export const CloseButton = ({onClick}) => {
    const {t} = useTranslation('ContactOptionsModal');
    return (
      <button aria-label={t('closeModal')} className={styles.closingIconWrapper} onClick={onClick}>
        <CustomIcon name="close" className={styles.icon} />
      </button>
    );
  };